import { render, staticRenderFns } from "./TableTotalAccident.vue?vue&type=template&id=b6a7b620&"
import script from "./TableTotalAccident.vue?vue&type=script&lang=js&"
export * from "./TableTotalAccident.vue?vue&type=script&lang=js&"
import style0 from "./TableTotalAccident.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports